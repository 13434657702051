import React from 'react';
import { Grid, Box, Typography } from "@mui/material";
import Fade from 'react-reveal/Fade';
import Evisit from "../../assets/images/projects/evisit.png";
import Electrik from "../../assets/images/projects/electric.png";
import YekraImg from "../../assets/images/projects/yekra-large.jpeg";
const PROJECTS = [
  {
    "image": Evisit,
    "title": "eVisit: Telemedicine Provider & Platform for Physicians",
    "discription": "eVisit is the #1 rated telehealth platform. This was made possible by providing end to end solutions to the privately practicing physicians & hospitals. The systems include live video chat, messaging, Rx, viewing medical history etc on any kind of device be it a Mobile, a Tablet or a PC \nWe make for 50% of their development team & have contributed to their mobile application, web application, & backend work.",
    "techs": ["React Native", "ReactJS", "Rails"]
  },
  {
    "image": Electrik,
    "title": "Electrik Folio",
    "discription": "Electrik is the free website builder for creatives. Mind blowing galleries, real-time client collaboration, One-click mobile designs, Endless layout options, Full storage solution, Stunning Blogs, Portfolio hosting, Custom domain, Password protected galleries, Electrik Story Builder, Client feedback tool. \nWe are the software team behind the electrik folio, managed directly by Josh. Josh is one of the most creative people we have worked with and we are glad to have that opportunity.",
    "techs": ["ReactJS", ".Net"]
  },
  {
    "image": YekraImg,
    "title": "YEKRA LLC",
    "discription": "Yekra used Sporks video pay wall technology to distribute films online for Indie film makers and large studios including Sony pictures. Spork handled the application development using Ruby on Rails and the front end work using AngularJS.",
    "techs": [ "AngularJS", "Rails"]
  }, 
];

const Projects = () => {

  const projectItem = (project, index) => {
    let { title, discription, techs, image } = project;

    let fadeProp = index % 2 === 0 ? { left: true } : { right: true }
    return <Fade {...fadeProp} key={index}>
      <Box key={index} sx={{ display: 'flex', flexWrap: 'wrap' }} className={index & 1 ? "odd project-item" : "even project-item"}>
        <Box className="content">
          <Typography component="h3" className="header">
            {title}
          </Typography>
          {discription.split(/\r?\n/).map((pragraph, i) => {
            return <Typography key={i} component="p">
              {pragraph}
            </Typography>
          })}
          <Box sx={{ display: 'flex' }} className="tech-list">
            {
              techs.map((tech, i) => {
                return <Box key={i} className="tech-item">
                  <span>{tech}</span>
                </Box>
              })
            }
          </Box>
        </Box>
        <Box className="image">
          <img src={image} alt="gallery image" className="gallery-img" />
        </Box>
      </Box>
    </Fade>
  }

  return (
    <section className="projects">
      <Grid container>
        {PROJECTS.map((project, index) => projectItem(project, index))}
      </Grid>
    </section>
  );
};

export default Projects;
