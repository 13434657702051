import React, { useEffect } from "react";
import DefaultLayout from "../components/Layouts/DefaultLayout";
import Seo from '../components/seo';
import Projects from "../components/Projects";

export default function OurWork(props) {
  useEffect(() => {
    window.navigateFrom = 'work';
  }, []);

  return <DefaultLayout {...props}>
    <Seo title="Our work" description="We have built software products for industry leaders and for many startups who have raised fundings in millions. JavaScript, .Net, Java and Agile  are our thing. Check out our portfolio." />
    <div className="work-container">
      <header className="project-header">
        <div>Recent</div>
        <div>Projects</div>
      </header>
      <Projects />
    </div>
  </DefaultLayout>
}
